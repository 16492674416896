/**
 * @generated SignedSource<<fe4c069f54a3ed05a2de4a58406cc1d8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type GoalScoringMode = "OKR" | "SIMPLE";
export type WorkspaceType = "GLOBAL_EXPERIENCE" | "PRODUCT";
import type { FragmentRefs } from "relay-runtime";
export type storeWorkspace$data = {
  readonly workspace: {
    readonly activationId: string | null;
    readonly aiConfig: {
      readonly enabled: boolean;
    } | null;
    readonly cloudId: string;
    readonly cloudName: string | null;
    readonly cloudUrl: string | null;
    readonly goalScoringMode: GoalScoringMode;
    readonly id: string;
    readonly keyPrefix: string;
    readonly name: string;
    readonly organisationId: string;
    readonly timezone: string;
    readonly type: WorkspaceType;
    readonly userHasInvitePermission: boolean | null;
    readonly userIsOrgAdmin: boolean;
    readonly uuid: string;
  } | null;
  readonly workspaceCount: number | null;
  readonly " $fragmentType": "storeWorkspace";
};
export type storeWorkspace$key = {
  readonly " $data"?: storeWorkspace$data;
  readonly " $fragmentSpreads": FragmentRefs<"storeWorkspace">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "storeWorkspace"
};

(node as any).hash = "c667b5c51ae412478d40d1678216aea8";

export default node;
