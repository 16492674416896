import { MatchedRoute, generatePath } from 'react-resource-router';

import { getConfig } from '@townsquare/config';
import { EMBED_ROUTE_NAMES } from '@townsquare/embeds';
import { redirect } from '@townsquare/facade/window';
import { getRouteParams } from '@townsquare/router/primitives';
import { routeDefinitions } from '@townsquare/router/routes';
import { createSearchParamsFromCurrentUrl } from '@townsquare/url-utils/search-params';

const config = getConfig();

export function globexRedirect(matchedRoute: MatchedRoute, routeContext: { orgId: string; cloudId: string }) {
  const fallbackDestination = `${config.homeUrl}/o/${routeContext.orgId}?cloudId=${routeContext.cloudId}`;
  const matchingRoute = routeDefinitions.find(
    globExRoute =>
      globExRoute.name === matchedRoute.route.name ||
      // map give kudos to globex create kudos
      (matchedRoute.route.path.includes('give-kudos') && globExRoute.name === EMBED_ROUTE_NAMES.createKudosOld),
  );
  const queryParams = createSearchParamsFromCurrentUrl();

  if (matchingRoute) {
    const params = getRouteParams(
      matchedRoute.match.params,
      matchingRoute.routeType,
      routeContext.orgId,
      routeContext.cloudId,
      'GLOBAL_EXPERIENCE',
    );

    const destination = generatePath(matchingRoute.path, params);

    if (matchingRoute.routeType === 'global' && !queryParams.has('cloudId')) {
      queryParams.set('cloudId', routeContext.cloudId);
    }

    redirect(
      queryParams.toString().length
        ? `${config.homeUrl}${destination}?${queryParams}`
        : `${config.homeUrl}${destination}`,
    );
    return;
  }

  redirect(fallbackDestination);
}
