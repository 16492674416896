import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Redirect as RRRRedirect, RouteContext } from 'react-resource-router';

import { FourOhFourPage } from '@townsquare/404-page-view';
import { ChatView, CreateAgentView, EditAgentView } from '@townsquare/chat-view';
import {
  ADMIN_HUB_URL,
  CONFLUENCE_CATCH_ALL_PATH,
  CREATE_AGENT,
  CREATE_AGENT_PAGE,
  CREATE_KUDOS_EMBED_OLD_PATH,
  CREATE_KUDOS_EMBED_PATH,
  CREATE_KUDOS_PATH,
  EDIT_AGENT,
  FULL_PAGE_CHAT,
  FULL_PAGE_CHAT_PAGE,
  FULL_PAGE_NOTIFICATIONS_PAGE,
  FULL_PAGE_NOTIFICATIONS_PATH,
  FULL_PAGE_SEARCH_PAGE,
  FULL_PAGE_SEARCH_PATH,
  GOAL_DIRECTORY_PAGE,
  GOAL_DIRECTORY_PATH,
  GOAL_FEED_PAGE,
  GOAL_FEED_PATH,
  GOAL_PATH_PATTERN,
  HOME_PAGE,
  HOME_PATH,
  HOWTO_PAGE,
  JIRA_CATCH_ALL_PATH,
  JIRA_INTEGRATION_READY_PATH,
  JIRA_ISSUES_CATCH_ALL_PATH,
  JIRA_SECURE_CATCH_ALL_PATH,
  LABEL_PATH_PREFIX,
  NAME_REDIRECT_SUFFIX,
  NO_ACCESS_PATH,
  NO_WORKSPACE_UPDATES_PATH,
  ONBOARDING_PAGE,
  ONBOARDING_PATH,
  ONBOARDING_PRODUCT_OVERVIEW,
  ONBOARDING_PRODUCT_OVERVIEW_PATH,
  PATH_PREFIX,
  PEOPLE_DIRECTORY_PATH,
  PEOPLE_DIRECTORY_SEARCH_PATH,
  PEOPLE_KUDOS_PATH_PATTERN,
  PEOPLE_PATH_PATTERN,
  PEOPLE_TEAM_PATH_PATTERN,
  PEOPLE_WORK_PATH_PATTERN,
  POPUP_AUTH_COMPLETE_PATH,
  PROJECT_DIRECTORY_PAGE,
  PROJECT_DIRECTORY_PATH,
  PROJECT_FEED_PAGE,
  PROJECT_FEED_PATH,
  PROJECT_PAGE,
  PROJECT_PATH_PATTERN,
  READING_MODE_PAGE,
  READING_MODE_PATH,
  SAVED_VIEW_FEED_PAGE,
  SAVED_VIEW_FEED_PATH_PREFIX,
  STATUS_UPDATES_PAGE,
  TAG_FEED_PAGE,
  TAG_FEED_PATH_PREFIX,
  TEAM_FEED_PAGE,
  TEAM_FEED_PATH_PREFIX,
  TEAM_HEALTH_EMBED_PATH,
  TEAM_HEALTH_MONITOR_PATH,
  TEAM_HEALTH_PATH,
  TOPIC_PAGE_PATH_PREFIX,
  UPDATE_LIST_PAGE,
  YOUR_WORK_PAGE,
  YOUR_WORK_PATH,
  TAGS_DIRECTORY,
  TAGS_PATH,
  EDIT_AGENT_PAGE,
  PEOPLE_AGENT_PATH_PATTERN,
} from '@townsquare/config/routes';
import { directoryActiveViewResource, directoryFavoriteViewsResource } from '@townsquare/directory-header';
import {
  GoalEmbed,
  goalEmbedResource,
  goalsDirectoryEmbedResource,
  ProjectEmbed,
  projectEmbedResource,
  projectsDirectoryEmbedResource,
} from '@townsquare/embeds';
import { LabelEmbed, labelEmbedResource } from '@townsquare/embeds/label-embed';
import { StatusUpdatesView } from '@townsquare/empty-or-not-provisioned-state';
import { ForgeFullAppPage } from '@townsquare/forge';
import { goalHighlightsTabResource, GoalView, goalViewResource } from '@townsquare/goal-view';
import {
  GoalDirectory,
  goalDirectoryResource,
  goalDirectoryTableResource,
  GoalsNoWorkspaceDirectory,
} from '@townsquare/goals-directory-view';
import { NoWorkspaceHomeView } from '@townsquare/home-layout';
import { homebarSideNavigationResource } from '@townsquare/home-layout/resources';
import {
  goalUpdatesResource,
  GoalUpdatesView,
  HomeView,
  LastSelectedUpdatesPageRedirect,
  projectUpdatesResource,
  ProjectUpdatesView,
  SavedViewUpdates,
  savedViewUpdatesResource,
  stayInLoopResource,
  tagUpdatesResource,
  TagUUIDRedirect,
  tagUUIDRedirectResource,
  teamUpdatesResource,
  TeamUpdatesView,
} from '@townsquare/home-view';
import { HowToPage, howToResource } from '@townsquare/how-to-view';
import { FullPageNotificationsView } from '@townsquare/notifications-view';
import { onboardingResource } from '@townsquare/onboarding';
import { OnboardingView, onboardingViewResource } from '@townsquare/onboarding-view';
import { ProductOverview, projectDialogProjectOverviewResource } from '@townsquare/product-overview-view';
import {
  jiraIntegrationLinkResource,
  projectDecisionsTabResource,
  projectLearningsTabResource,
  projectRisksTabResource,
  projectUpdatesResource as projectUpdatesTabResource,
  ProjectView,
  projectViewResource,
} from '@townsquare/project-view';
import {
  ProjectDirectory,
  projectDirectoryResource,
  projectDirectoryViewResource,
  ProjectsEmptyOrNonProvisionedState,
} from '@townsquare/projects-directory-view';
import { ReadingMode } from '@townsquare/reading-mode-view';
import { readingModeResource } from '@townsquare/reading-mode-view/resources';
import { type RoutesWithOutlet, RouteWithOutlet } from '@townsquare/router';
import { Redirect } from '@townsquare/router/primitives';
import { rovoEntitlementResource } from '@townsquare/rovo/entitlement';
import { SearchView } from '@townsquare/search-view';
import { settingsPageResource, SettingsView } from '@townsquare/settings-view';
import { settingsRoute } from '@townsquare/settings-view/route';
import {
  NoWorkspaceStaffDirectoryView,
  staffDirectoryResource,
  StaffDirectoryView,
} from '@townsquare/staff-directory-view';
import { TagsDirectoryPage } from '@townsquare/tags-directory-view';
import { exploreResource } from '@townsquare/tags-directory-view/resources';
import { TEAM_HEALTH_ROUTE_NAME, TeamHealthEmbed, teamHealthResource } from '@townsquare/team-health-view';
import { titanEapCrossFlowQueryResource } from '@townsquare/titan-eap-cross-flow/resource';
import { TopicPage } from '@townsquare/topic-view';
import { topicResource } from '@townsquare/topic-view/resources';
import { tqlExplainerResource } from '@townsquare/tql';
import { FollowGoalAction, followGoalResource } from '@townsquare/url-actions/follow-goal';
import { FollowProjectAction, followProjectResource } from '@townsquare/url-actions/follow-project';
import {
  LookupRedirectAction,
  lookupActionGoalResource,
  lookupActionProjectResource,
} from '@townsquare/url-actions/lookup-redirect';
import { ReactionUpdateAction, reactionUpdateActionResource } from '@townsquare/url-actions/reaction-update';
import { SearchRedirectAction } from '@townsquare/url-actions/search-redirect';
import { YourWorkView, YourWorkViewNoWorkspace } from '@townsquare/your-work-view';

import SendSampleDigestEmailAction from './actions/SendSampleDigestEmailAction';
import GoalsDirectoryEmbed from './embeds/GoalsDirectory';
import ProjectsDirectoryEmbed from './embeds/ProjectsDirectory';
import { EMBED_ROUTE_NAMES } from './embeds/shared/embed-route-names';
import CreateKudosEmbed from './ui/CreateKudosEmbed';
import DirectoryPage from './ui/DirectoryPage';
import { NoAccessPage, RestrictedContentPage } from './ui/ErrorPage';
import ReturnFromPopUpAuth from './ui/ReturnFromPopUpAuth';
import ReturnToJiraIntegration from './ui/ReturnToJiraIntegration';
import TagPage from './ui/TagPage';
import { ConfluenceUrlRedirect, JiraUrlRedirect, ManageAccessAdminHubRedirect } from './ui/TenantedUrlRedirect';

export const HowToRedirect = () => <RRRRedirect to="/how" />;
export const WorkspaceSettingsRedirect = () => <Redirect to={settingsRoute} params={{ pageName: 'personal' }} />;

type FlagOptions = {
  noWorkspaceExperience?: boolean;
};

export const getRoutes = (flagOptions: FlagOptions = {}): RoutesWithOutlet => {
  const { noWorkspaceExperience = false } = flagOptions;

  if (noWorkspaceExperience) {
    return NO_WORKSPACE_ROUTES;
  }

  const routes = [
    // Redirects
    {
      component: ManageAccessAdminHubRedirect,
      exact: true,
      name: `admin-${NAME_REDIRECT_SUFFIX}`,
      path: ADMIN_HUB_URL,
      type: 'redirect',
    },
    {
      component: ReturnFromPopUpAuth,
      exact: true,
      name: `popup-auth-${NAME_REDIRECT_SUFFIX}`,
      path: POPUP_AUTH_COMPLETE_PATH,
      type: 'redirect',
    },
    {
      component: ReturnToJiraIntegration,
      exact: true,
      name: `jira-integration-${NAME_REDIRECT_SUFFIX}`,
      path: JIRA_INTEGRATION_READY_PATH,
      type: 'redirect',
    },
    {
      component: WorkspaceSettingsRedirect,
      exact: true,
      name: `workspace-settings-${NAME_REDIRECT_SUFFIX}`,
      path: PATH_PREFIX.SETTINGS,
      type: 'redirect',
    },
    {
      component: ConfluenceUrlRedirect,
      exact: false,
      name: `tenanted-url-confluence-${NAME_REDIRECT_SUFFIX}`,
      path: CONFLUENCE_CATCH_ALL_PATH,
      type: 'redirect',
    },
    {
      component: JiraUrlRedirect,
      exact: false,
      name: `tenanted-url-jira-${NAME_REDIRECT_SUFFIX}`,
      path: JIRA_CATCH_ALL_PATH,
      type: 'redirect',
    },
    {
      component: JiraUrlRedirect,
      exact: false,
      name: `tenanted-url-jira-issues-${NAME_REDIRECT_SUFFIX}`,
      path: JIRA_ISSUES_CATCH_ALL_PATH,
      type: 'redirect',
    },
    {
      component: JiraUrlRedirect,
      exact: false,
      name: `tenanted-url-jira-secure-${NAME_REDIRECT_SUFFIX}`,
      path: JIRA_SECURE_CATCH_ALL_PATH,
      type: 'redirect',
    },
    // Pages
    {
      component: SearchView,
      exact: true,
      name: FULL_PAGE_SEARCH_PAGE,
      path: FULL_PAGE_SEARCH_PATH,
      type: 'view',
    },
    {
      component: ChatView,
      exact: true,
      name: FULL_PAGE_CHAT_PAGE,
      path: FULL_PAGE_CHAT,
      type: 'view',
      query: [
        'rovoChat!=false',
        'rovoChat.agentId!=false',
        'rovoChat.cloudId!=false',
        'rovoChat.conversationId!=false',
        'rovoChat.pathway!=false',
        'rovoChat.prompt!=false',
        'rovoChat!=false',
        'rovoChatAgentId!=false',
        'rovoChatCloudId!=false',
        'rovoChatConversationId!=false',
        'rovoChatPathway!=false',
        'rovoChatPrompt!=false',
        'rovoChatTriggerOpen!=false',
      ],
      resources: [rovoEntitlementResource],
    },
    {
      component: CreateAgentView,
      exact: true,
      name: CREATE_AGENT_PAGE,
      path: CREATE_AGENT,
      type: 'view',
      query: [
        'rovoChat!=false',
        'rovoChat.agentId!=false',
        'rovoChat.cloudId!=false',
        'rovoChat.conversationId!=false',
        'rovoChat.pathway!=false',
        'rovoChat.prompt!=false',
        'rovoChat!=false',
        'rovoChatAgentId!=false',
        'rovoChatCloudId!=false',
        'rovoChatConversationId!=false',
        'rovoChatPathway!=false',
        'rovoChatPrompt!=false',
        'rovoChatTriggerOpen!=false',
      ],
      resources: [rovoEntitlementResource],
    },
    {
      component: EditAgentView,
      exact: true,
      name: EDIT_AGENT_PAGE,
      path: EDIT_AGENT,
      query: [
        'rovoChat!=false',
        'rovoChat.agentId!=false',
        'rovoChat.cloudId!=false',
        'rovoChat.conversationId!=false',
        'rovoChat.pathway!=false',
        'rovoChat.prompt!=false',
        'rovoChat!=false',
        'rovoChatAgentId!=false',
        'rovoChatCloudId!=false',
        'rovoChatConversationId!=false',
        'rovoChatPathway!=false',
        'rovoChatPrompt!=false',
        'rovoChatTriggerOpen!=false',
      ],
      type: 'view',
      resources: [rovoEntitlementResource],
    },
    {
      component: OnboardingView,
      exact: true,
      name: ONBOARDING_PAGE,
      path: ONBOARDING_PATH,
      resources: [onboardingViewResource],
      type: 'view',
    },
    {
      component: ProductOverview,
      exact: true,
      name: ONBOARDING_PRODUCT_OVERVIEW,
      path: ONBOARDING_PRODUCT_OVERVIEW_PATH,
      resources: [projectDialogProjectOverviewResource],
      type: 'view',
    },
    {
      component: HowToPage,
      exact: true,
      name: HOWTO_PAGE,
      path: PATH_PREFIX.HOW,
      resources: [howToResource],
      type: 'view',
    },
    {
      component: NoAccessPage,
      exact: true,
      name: 'noaccess',
      path: NO_ACCESS_PATH,
      type: 'view',
    },
    {
      component: LastSelectedUpdatesPageRedirect,
      exact: true,
      name: HOME_PAGE,
      path: HOME_PATH,
      type: 'view',
    },
    {
      component: LastSelectedUpdatesPageRedirect,
      exact: true,
      name: UPDATE_LIST_PAGE,
      path: PATH_PREFIX.UPDATES,
      type: 'view',
    },
    {
      component: HomeView,
      outlet: YourWorkView,
      exact: true,
      name: YOUR_WORK_PAGE,
      path: YOUR_WORK_PATH,
      resources: [homebarSideNavigationResource],
      type: 'view',
      subproduct: 'homepage',
    },
    {
      component: FullPageNotificationsView,
      exact: true,
      name: FULL_PAGE_NOTIFICATIONS_PAGE,
      path: FULL_PAGE_NOTIFICATIONS_PATH,
      type: 'view',
      subproduct: 'notifications',
    },
    {
      component: HomeView,
      outlet: ProjectUpdatesView,
      exact: true,
      name: PROJECT_FEED_PAGE,
      path: PROJECT_FEED_PATH,
      resources: [onboardingResource, projectUpdatesResource, homebarSideNavigationResource, stayInLoopResource],
      type: 'view',
      subproduct: 'watermelon-projects',
    },
    {
      component: HomeView,
      outlet: GoalUpdatesView,
      exact: true,
      name: GOAL_FEED_PAGE,
      path: GOAL_FEED_PATH,
      resources: [onboardingResource, goalUpdatesResource, homebarSideNavigationResource],
      type: 'view',
      subproduct: 'watermelon-goals',
    },
    {
      component: ReadingMode,
      exact: true,
      name: READING_MODE_PAGE,
      path: READING_MODE_PATH,
      resources: [readingModeResource],
      type: 'view',
      subproduct: (routerState: RouteContext) => {
        const entity = routerState.match.params.updateType;
        return entity === 'projects' ? 'watermelon-projects' : 'watermelon-goals';
      },
    },
    {
      component: ProjectDirectory,
      exact: false,
      name: PROJECT_DIRECTORY_PAGE,
      path: PROJECT_DIRECTORY_PATH,
      resources: [
        onboardingResource,
        projectDirectoryResource,
        projectDirectoryViewResource,
        directoryFavoriteViewsResource,
        tqlExplainerResource,
        directoryActiveViewResource,
      ],
      type: 'view',
      subproduct: 'watermelon-projects',
    },
    {
      component: ProjectView,
      exact: true,
      name: PROJECT_PAGE,
      path: PROJECT_PATH_PATTERN,
      resources: [
        projectLearningsTabResource,
        projectRisksTabResource,
        projectDecisionsTabResource,
        projectViewResource,
        projectUpdatesTabResource,
        jiraIntegrationLinkResource,
        onboardingResource,
      ],
      type: 'view',
      subproduct: 'watermelon-projects',
    },
    {
      component: HowToRedirect,
      exact: true,
      name: 'empty-project-redirect',
      path: PATH_PREFIX.PROJECT,
      type: 'redirect',
    },
    {
      component: TagPage,
      exact: true,
      name: 'tagChannelList',
      path: `${LABEL_PATH_PREFIX}/:id`,
      type: 'view',
      subproduct: 'watermelon-topics',
    },
    {
      component: HomeView,
      outlet: TagUUIDRedirect,
      exact: true,
      name: TAG_FEED_PAGE,
      path: `${TAG_FEED_PATH_PREFIX}/:id`,
      resources: [tagUUIDRedirectResource, homebarSideNavigationResource, tagUpdatesResource],
      type: 'view',
      subproduct: 'watermelon-topics',
    },
    {
      component: TopicPage,
      exact: true,
      name: 'tag',
      path: `${TOPIC_PAGE_PATH_PREFIX}/:uuid/:tabName?/:viewMode?`,
      resources: [topicResource],
      type: 'view',
      subproduct: 'watermelon-topics',
    },
    {
      component: HomeView,
      outlet: TeamUpdatesView,
      exact: true,
      name: TEAM_FEED_PAGE,
      path: `${TEAM_FEED_PATH_PREFIX}/:id`,
      resources: [teamUpdatesResource, homebarSideNavigationResource],
      type: 'view',
    },
    {
      component: HomeView,
      outlet: SavedViewUpdates,
      exact: true,
      name: SAVED_VIEW_FEED_PAGE,
      path: `${SAVED_VIEW_FEED_PATH_PREFIX}/:uuid`,
      resources: [homebarSideNavigationResource, savedViewUpdatesResource],
      type: 'view',
    },
    {
      exact: true,
      name: TAGS_DIRECTORY,
      path: TAGS_PATH,
      component: TagsDirectoryPage,
      resources: [exploreResource, onboardingResource, tqlExplainerResource],
      type: 'view',
      subproduct: 'watermelon-tags',
    },
    {
      component: StaffDirectoryView,
      exact: true,
      name: 'directory',
      path: PEOPLE_DIRECTORY_SEARCH_PATH,
      resources: [onboardingResource, staffDirectoryResource],
      type: 'view',
      subproduct: 'people-team-directory',
    },
    {
      component: DirectoryPage,
      exact: true,
      name: 'agent-profile-page',
      path: PEOPLE_AGENT_PATH_PATTERN,
      resources: [onboardingResource],
      type: 'view',
      subproduct: 'people-team-directory',
    },
    {
      component: DirectoryPage,
      exact: true,
      name: 'profile-page',
      path: PEOPLE_PATH_PATTERN,
      resources: [onboardingResource],
      type: 'view',
      subproduct: 'people-team-directory',
    },
    {
      component: DirectoryPage,
      exact: true,
      name: 'profile-page',
      path: PEOPLE_WORK_PATH_PATTERN,
      resources: [onboardingResource],
      type: 'view',
      subproduct: 'people-team-directory',
    },
    {
      component: DirectoryPage,
      exact: true,
      name: 'kudos-profile-page',
      path: PEOPLE_KUDOS_PATH_PATTERN,
      resources: [onboardingResource],
      type: 'view',
      subproduct: 'people-team-directory',
    },
    {
      component: DirectoryPage,
      exact: true,
      name: 'team-profile-page',
      path: PEOPLE_TEAM_PATH_PATTERN,
      resources: [onboardingResource],
      type: 'view',
      subproduct: 'people-team-directory',
    },
    {
      component: StaffDirectoryView,
      exact: false,
      name: 'directory',
      path: PEOPLE_DIRECTORY_PATH,
      resources: [onboardingResource, staffDirectoryResource],
      type: 'view',
      subproduct: 'people-team-directory',
    },
    {
      component: GoalDirectory,
      exact: false,
      name: GOAL_DIRECTORY_PAGE,
      path: GOAL_DIRECTORY_PATH,
      resources: [
        onboardingResource,
        goalDirectoryResource,
        goalDirectoryTableResource,
        tqlExplainerResource,
        directoryFavoriteViewsResource,
        directoryActiveViewResource,
      ],
      type: 'view',
      subproduct: 'watermelon-goals',
    },
    {
      component: GoalView,
      exact: true,
      name: 'goal',
      path: GOAL_PATH_PATTERN,
      resources: [onboardingResource, goalViewResource, goalHighlightsTabResource],
      type: 'view',
      subproduct: 'watermelon-goals',
    },
    {
      component: SettingsView,
      exact: false,
      name: 'settings',
      path: `${PATH_PREFIX.SETTINGS}/:pageName/:entity?`,
      resources: [settingsPageResource],
      type: 'view',
    },
    {
      component: ForgeFullAppPage,
      exact: true,
      name: 'forgeAppPage',
      path: `${PATH_PREFIX.FORGE_APP}/:id/:title`,
      type: 'view',
    },
    {
      component: CreateKudosEmbed, // Place holder for non-embed version
      exact: true,
      name: 'create-kudos',
      path: CREATE_KUDOS_PATH,
      type: 'view',
      subproduct: 'watermelon-kudos',
    },
    {
      component: TeamHealthEmbed,
      exact: true,
      name: TEAM_HEALTH_ROUTE_NAME,
      path: TEAM_HEALTH_PATH,
      resources: [teamHealthResource],
      type: 'embed',
      subproduct: 'watermelon-health-monitor',
    },
    {
      component: TeamHealthEmbed,
      exact: true,
      name: TEAM_HEALTH_ROUTE_NAME,
      path: TEAM_HEALTH_MONITOR_PATH,
      resources: [teamHealthResource],
      type: 'embed',
      subproduct: 'watermelon-health-monitor',
    },
    // Actions
    {
      component: FollowProjectAction,
      exact: true,
      name: 'followProjectAction',
      path: `${PATH_PREFIX.ACTION}/project/:key/:action(follow|unfollow)`,
      resources: [followProjectResource],
      type: 'action',
    },
    {
      component: ReactionUpdateAction,
      exact: true,
      name: 'reactionAction',
      path: `${PATH_PREFIX.ACTION}/update/:id/reaction`,
      resources: [reactionUpdateActionResource],
      type: 'action',
    },
    {
      component: FollowGoalAction,
      exact: true,
      name: 'followGoalAction',
      path: `${PATH_PREFIX.ACTION}/goal/:key/:action(follow|unfollow)`,
      resources: [followGoalResource],
      type: 'action',
    },
    {
      component: SendSampleDigestEmailAction,
      exact: true,
      name: 'sendSampleDigestEmailAction',
      path: `${PATH_PREFIX.ACTION}/sample-digest-email`,
      type: 'action',
    },
    {
      component: LookupRedirectAction,
      exact: true,
      name: 'lookupAction',
      path: `${PATH_PREFIX.ACTION}/lookup/:key`,
      resources: [lookupActionGoalResource, lookupActionProjectResource],
      type: 'action',
    },
    {
      component: SearchRedirectAction,
      exact: true,
      name: 'searchAction',
      path: `${PATH_PREFIX.ACTION}/search/:entity`,
      type: 'action',
    },
    // Embeds
    {
      component: LabelEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.label,
      path: `${PATH_PREFIX.EMBEDS}/label/:id/:uuid?`,
      type: 'embed',
      subproduct: 'watermelon-topics',
      resources: [labelEmbedResource],
    },
    {
      component: GoalsDirectoryEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.goalsDirectory,
      path: `${PATH_PREFIX.EMBEDS}/goals`,
      resources: [goalsDirectoryEmbedResource],
      type: 'embed',
      subproduct: 'watermelon-goals',
    },
    {
      component: ProjectsDirectoryEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.projectsDirectory,
      path: `${PATH_PREFIX.EMBEDS}/projects`,
      resources: [projectsDirectoryEmbedResource],
      type: 'embed',
      subproduct: 'watermelon-projects',
    },
    {
      component: ProjectEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.projectUpdate,
      path: `${PATH_PREFIX.EMBEDS}/project/:projectKey/update`,
      resources: [projectEmbedResource],
      type: 'embed',
      subproduct: 'watermelon-projects',
    },
    {
      component: ProjectEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.projectAbout,
      path: `${PATH_PREFIX.EMBEDS}/project/:projectKey/about`,
      resources: [projectEmbedResource],
      type: 'embed',
      subproduct: 'watermelon-projects',
    },
    {
      component: GoalEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.goalUpdate,
      path: `${PATH_PREFIX.EMBEDS}/goal/:goalKey/update`,
      resources: [goalEmbedResource],
      type: 'embed',
      subproduct: 'watermelon-goals',
    },
    {
      component: GoalEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.goalAbout,
      path: `${PATH_PREFIX.EMBEDS}/goal/:goalKey/about`,
      resources: [goalEmbedResource],
      type: 'embed',
      subproduct: 'watermelon-goals',
    },
    {
      component: CreateKudosEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.createKudosOld,
      path: CREATE_KUDOS_EMBED_OLD_PATH,
      type: 'embed',
      subproduct: 'watermelon-kudos',
    },
    {
      component: CreateKudosEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.createKudos,
      path: CREATE_KUDOS_EMBED_PATH,
      type: 'embed',
      subproduct: 'watermelon-kudos',
    },
    {
      component: TeamHealthEmbed,
      exact: true,
      name: EMBED_ROUTE_NAMES.teamHealth,
      path: TEAM_HEALTH_EMBED_PATH,
      resources: [teamHealthResource],
      type: 'embed',
      subproduct: 'watermelon-health-monitor',
    },
    {
      component: RestrictedContentPage,
      exact: true,
      name: '403-page',
      path: PATH_PREFIX.FOURZEROTHREE,
      type: 'view',
    },
  ];

  const allRoutes = [
    ...routes,
    // 404 - must go last
    {
      component: FourOhFourPage,
      exact: false,
      name: '404-page',
      path: '/*',
      type: 'view',
    },
  ];

  return allRoutes as RoutesWithOutlet;
};

export const NO_WORKSPACE_ROUTES: RouteWithOutlet[] = [
  {
    component: OnboardingView,
    exact: true,
    name: ONBOARDING_PAGE,
    path: ONBOARDING_PATH,
    resources: [onboardingViewResource],
    type: 'view',
  },
  {
    component: GoalsNoWorkspaceDirectory,
    exact: false,
    name: GOAL_DIRECTORY_PAGE,
    path: GOAL_DIRECTORY_PATH,
    resources: [titanEapCrossFlowQueryResource],
    type: 'view',
    subproduct: 'homepage',
  },
  {
    component: ProjectsEmptyOrNonProvisionedState,
    exact: false,
    name: PROJECT_DIRECTORY_PAGE,
    path: PROJECT_DIRECTORY_PATH,
    resources: [titanEapCrossFlowQueryResource],
    type: 'view',
    subproduct: 'homepage',
  },
  {
    component: NoWorkspaceStaffDirectoryView,
    exact: true,
    name: 'directory',
    path: PEOPLE_DIRECTORY_PATH,
    resources: [titanEapCrossFlowQueryResource],
    type: 'view',
    subproduct: 'homepage',
  },
  {
    component: NoWorkspaceHomeView,
    outlet: YourWorkViewNoWorkspace,
    exact: true,
    name: YOUR_WORK_PAGE,
    path: YOUR_WORK_PATH,
    resources: [titanEapCrossFlowQueryResource],
    type: 'view',
    subproduct: 'homepage',
  },
  {
    component: NoWorkspaceHomeView,
    outlet: StatusUpdatesView,
    exact: true,
    name: STATUS_UPDATES_PAGE,
    path: NO_WORKSPACE_UPDATES_PATH,
    resources: [titanEapCrossFlowQueryResource],
    type: 'view',
    subproduct: 'homepage',
  },
  {
    component: FullPageNotificationsView,
    exact: true,
    name: FULL_PAGE_NOTIFICATIONS_PAGE,
    path: FULL_PAGE_NOTIFICATIONS_PATH,
    type: 'view',
    subproduct: 'notifications',
  },
  {
    component: ReturnToJiraIntegration,
    exact: true,
    name: `jira-integration-${NAME_REDIRECT_SUFFIX}`,
    path: JIRA_INTEGRATION_READY_PATH,
    type: 'redirect',
  },
  // 404 - must go last
  {
    component: FourOhFourPage,
    exact: false,
    name: '404-page',
    path: '/*',
    type: 'view',
  },
];
